import React from 'react';
import * as FormStyling from "../Form.module.scss";

const Textarea = ({
  label,
  name,
  description,
  isRequired,
  register,
  errors,
}) => {
  return (
    <div className="field">
      <label className="field-input">
        <textarea
          className={
            errors[name]
              ? `${FormStyling.formInput} ${FormStyling.invalid}`
              : `${FormStyling.formInput}`
          }
          type={label}
          {...register(name, { required: isRequired })}
          rows="4"
        ></textarea>
        <label className={`${FormStyling.fieldInputLabel} field-label`}>
          {label}
          {isRequired && <span className="primary-color"> *</span>}
        </label>
      </label>
      {description && (
        <span className={`col field-message ${FormStyling.aboutMessage}`}>
          {description}
        </span>
      )}
    </div>
  );
};
export default Textarea;
